import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SimpleTable from "./SimpleTable";
import AllocStatusTable from "./AllocStatusTable";
// import getPageData2 from "../../../../api/sustainability/reports/getPageData";
import getConfirmState from "../../../../api/sustainability/reports/getConfirmState";
import getDownloadReport, {
  Tfiletype,
} from "../../../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../../../components/modal/ModalInfo";
import {
  allocationTableData,
  sustainableTableData,
  reportedTableData,
  jointTableData,
  aggregatedTableData,
  summarizedReportTableData,
  calculationTaxTableData,
  availablePoolBiotemplatesData,
  poolStatusDieselData,
  poolStatusGasolineData,
} from "./tableData";
import { FormattedMessage } from "react-intl";
import SmartTable from "../../../../components/SmartTable";
import ModalConfirmDelivery from "./ModalConfirmDelivery";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmartSelect from "../../../../components/SmartSelect";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import clsx from "clsx";
import {getRoadUseDraftReport} from "../../../../api/sustainability/DraftReports/roadUse/getDraftReport";
import {useHistory} from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        fontSize: "14px",
      },
    },
    titleSection: {
      marginBottom: theme.spacing(3),
    },
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    select: {
      width: "150px",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    sectionDownloads: {
      marginBottom: theme.spacing(2),
    },
    section: {
      marginBottom: theme.spacing(3),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    card: {
      height: "100%",
    },
    cardContent: {
      padding: theme.spacing(3.75),
      paddingTop: theme.spacing(4),
      "&:last-child": {
        paddingBottom: theme.spacing(3.75),
      },
    },
    cardTitle: {
      marginBottom: theme.spacing(0.5),
    },
    cardSubTitle: {
      fontSize: theme.typography.h5.fontSize,
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: 0,
        paddingBottom: 0,
        height: theme.spacing(4),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: theme.palette.grey[300],
      },
    },
    tableContainer: {
      maxHeight: "450px",
      overflowY: "auto", // "scroll",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    btnDownloads: {
      height: "100%",
    },
    circularProgress: {
      margin: "80px",
      width: "48px",
      height: "48px",
    },
  })
);

interface Props {
  activePeriod: any;
  isSimulation: boolean;
  isJoint: boolean;
  toggleSimulation: any;
  data: any;
  getPageData: any;
  isFetching: boolean;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
}

const ReportsView: React.FC<Props> = ({
  activePeriod,
  isSimulation,
  isJoint,
  toggleSimulation,
  data,
  getPageData,
  isFetching,
  taxPeriods,
  selectedYear,
  setSelectedYear,
}) => {
  const history = useHistory();
  const [report, setReport] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [selectedPart, setSelectedPart] = useState<any>(activePeriod.name);
  const [
    showConfirmDeliveryModal,
    setShowConfirmDeliveryModal,
  ] = useState<boolean>(false);

  const openErrorModal = (msg: string) => {
    setErrorMessage(msg);
    setShowErrorModal(true);
  };
  const closeErrorModal = () => {
    setErrorMessage("");
    setShowErrorModal(false);
  };

  const openConfirmDeliveryModal = () => {
    setShowConfirmDeliveryModal(true);
  };

  const closeConfirmDeliveryModal = () => {
    setShowConfirmDeliveryModal(false);
  };

  const classes = useStyles();

  const handleDownload = async (type: Tfiletype) => {
    const response = await getDownloadReport({
      type,
      mandate_type: "roaduse",
      taxYear: selectedYear,
    });
    if (response === null) {
      openErrorModal(
        "Failed to download file: Possible errors: no file for selected year or other errors."
      );
    }

    if (response !== null) {
      // emulate file download by clicking on an <a /> link
      const url = window.URL.createObjectURL(response);
      const fileName =
          type === "draft_report_excel"
              ? "sustainability-draft-report"
              : type.indexOf("sub") > -1
                  ? "sustainability-subreport"
                  : "sustainability-report";
      const fileExt = type.indexOf("excel") > -1 ? "xlsx" : "json";

      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}-roaduse-${selectedYear}.${fileExt}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handleConfirmationStatus = async () => {
    const res = await getConfirmState({ taxYear: selectedYear });
    if (res === null) {
      openErrorModal(
        "Failed to confirm delivery. Possible errors: correct reporting year is not selected or other errors."
      );
    } else {
      openErrorModal(
          "Delivery Confirmed."
      );
      getPageData(selectedYear);
    }
  };

  // on init
  useEffect(() => {
    setSelectedYear(activePeriod.year);
  }, []);

  useEffect(() => {
    getPageData(selectedYear);
  }, [selectedYear]);

  // report overview
  const goToReportOverview = () => {
    history.push(`/sustainability/reportoverview/`);
  };

  // sth need to be done here
  const confirmationStatus =
    Array.isArray(data.confirmation_status) &&
    data.confirmation_status.includes(true);
  const collectiveFulfillement = data.collective_fulfillment === true;

  // Extract required table data from API
  const selectedPartIndex =
    data && data.tax_year_seq && data.tax_year_seq.indexOf(selectedPart) != -1
      ? data.tax_year_seq.indexOf(selectedPart)
      : 0;

  const allocationStatusData =
    data &&
    data.requirement_states &&
    data.requirement_states[selectedPartIndex]
      ? allocationTableData.extractAPIData(
          data.requirement_states[selectedPartIndex],
          isJoint
        )
      : [];

  const sustainableBiofuelConstructionData =
    data &&
    data.sustainable_batches &&
    data.sustainable_batches[selectedPartIndex]
      ? sustainableTableData.extractAPIData(
          data.sustainable_batches[selectedPartIndex][0][0]
        )
      : [];
  const sustainableBiofuelRoadTypeData =
    data &&
    data.sustainable_batches &&
    data.sustainable_batches[selectedPartIndex]
      ? sustainableTableData.extractAPIData(
          data.sustainable_batches[selectedPartIndex][0][1]
        )
      : [];
  const reportedVolumesData =
    data && data.reported_volumes && data.reported_volumes[selectedPartIndex]
      ? reportedTableData.extractAPIData(
          data.reported_volumes[selectedPartIndex][0]
        )
      : [];
  // neet to check this one
  const jointFulfillmentData =
    data &&
    data.joint_fulfillments &&
    data.joint_fulfillments[selectedPartIndex]
      ? jointTableData.extractAPIData(
          data.joint_fulfillments[selectedPartIndex]
        )
      : [];

  const availPoolData =
    data &&
    data.available_pool_biotemplates &&
    data.available_pool_biotemplates[selectedPartIndex]
      ? availablePoolBiotemplatesData.extractAPIData(
          data.available_pool_biotemplates[selectedPartIndex][0]
        )
      : [];
  const dieselData =
    data &&
    data.pool_status &&
    poolStatusDieselData.extractAPIData(data.pool_status[selectedPartIndex]);

  const gasolineData =
    data &&
    data.pool_status &&
    poolStatusGasolineData.extractAPIData(data.pool_status[selectedPartIndex]);

  // const checkNull = (data: any) => {
  //   let check = true;
  //   if (data && Array.isArray(data) && data.length > 0) {
  //     console.log("hey" + data);
  //   } else {
  //     check = false;
  //   }

  //   return check;
  // };
  useEffect(() => {
    const fetchReport = async () => {
      const data = await getRoadUseDraftReport();
      if (data) {
        setReport(data);
      } else {
      }
    };

    fetchReport();
  }, []);

  // @ts-ignore
  if (!report || !report.data || !report.data.mandate_fulfillment_status || !report.data.mandate_fulfillment_status.fulfillment_status) {
    return <p></p>;
  }

  // @ts-ignore
  const mandateFulfillmentStatus = report.data.mandate_fulfillment_status;
  // @ts-ignore
  const volumeSummary = report.data.volume_summary
  // @ts-ignore
  const allocatedBatches = report.data.allocated_batches

  const bgColorGreen = "#cce6d8"
  const bgColorYellow = "#ffd588"
  const bgColorRed = "#ffdad1"

  let statusColorGasoline = bgColorGreen
  if (mandateFulfillmentStatus.fulfillment_status.gasoline_mandate === "Not fulfilled"){
    statusColorGasoline = bgColorRed
  }
  else if (mandateFulfillmentStatus.fulfillment_status.gasoline_mandate === "Over-allocated"){
    statusColorGasoline = bgColorYellow
  }

  let statusColorAdvanced = bgColorGreen
  if (mandateFulfillmentStatus.fulfillment_status.advanced_mandate === "Not fulfilled"){
    statusColorAdvanced = bgColorRed
  }
  else if (mandateFulfillmentStatus.fulfillment_status.advanced_mandate === "Over-allocated"){
    statusColorAdvanced = bgColorYellow
  }

  let statusColorGeneral = bgColorGreen
  if (mandateFulfillmentStatus.fulfillment_status.general_mandate === "Not fulfilled"){
    statusColorGeneral = bgColorRed
  }
  else if (mandateFulfillmentStatus.fulfillment_status.general_mandate === "Over-allocated"){
    statusColorGeneral = bgColorYellow
  }

  let statusColorReqByCust = bgColorGreen
  if (mandateFulfillmentStatus.fulfillment_status.req_by_cust_mandate_LE === "Not fulfilled"){
    statusColorReqByCust = bgColorRed
  }
  else if (mandateFulfillmentStatus.fulfillment_status.req_by_cust_mandate_LE === "Over-allocated"){
    statusColorReqByCust = bgColorYellow
  }

  let statusColorAfterFulfill = bgColorGreen
  if (mandateFulfillmentStatus.fulfillment_status.after_mandate_fulfill_LE === "Not fulfilled"){
    statusColorAfterFulfill = bgColorRed
  }
  else if (mandateFulfillmentStatus.fulfillment_status.after_mandate_fulfill_LE === "Over-allocated"){
    statusColorAfterFulfill = bgColorYellow
  }
  // @ts-ignore
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.titleSection}
      >
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
           Draft report: Roaduse
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            {!isFetching &&
              data &&
              data.tax_year_seq &&
              data.tax_year_seq.length > 1 && (
                <Grid item>
                  <Select
                    fullWidth
                    className={classes.select}
                    input={<OutlinedInput labelWidth={0} />}
                    value={selectedPart}
                    onChange={e => setSelectedPart(e.target.value)}
                  >
                    {data.tax_year_seq.map(
                      (partName: string, index: number) => (
                        <MenuItem value={partName}>
                          {"Part " +
                            partName
                              .replace(selectedYear + "", "")
                              .match(/\d+/)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Grid>
              )}

            <Grid item>
              {/* <SmartSelect
                name="tax_year"
                value={selectedYear}
                onChange={(e: any) => setSelectedYear(e.target.value)}
                style={{ width: "110px" }}
                displayEmpty
                type="tax_years"
              /> */}

              <Select
                fullWidth
                className={classes.select}
                input={<OutlinedInput labelWidth={0} />}
                value={selectedYear}
                onChange={e => setSelectedYear(e.target.value)}
              >
                {taxPeriods
                  ? taxPeriods.map((year: any, index: number) => (
                      <MenuItem
                        value={Object.keys(year)[0]}
                        key={
                          "Sus.View_Reports_Year_" +
                          Object.keys(year)[0] +
                          "-" +
                          index
                        }
                      >
                        {Object.keys(year)[0]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>

            <Grid item>
              <ToggleButton
                labels={["Reporting", "Simulation"]}
                defaultLabelIndex={isSimulation ? 1 : 0}
                size="small"
                bgWhite
                handleChange={toggleSimulation}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.sectionDownloads}>
        <Grid item xs={7}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Allocation Mode: Reporting
                  </Typography>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Period: 2023
                  </Typography>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Fulfillment type: Own
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.cardTitle}>
                <p>Report File and Actions</p>
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <ButtonRounded
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleDownload("draft_report_excel")}
                  >
                    Draft Report (Excel)
                  </ButtonRounded>
                </Grid>
                <Grid item xs={4}>
                  <ButtonRounded
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleDownload("report_excel")}
                  >
                    Tilde report file
                  </ButtonRounded>
                </Grid>
                <Grid item xs={4}>
                  <ButtonRounded
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={goToReportOverview}
                      disabled={false}
                      // className={classes.btnDownloads}
                  >
                    Report overview
                  </ButtonRounded>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isFetching ? (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress className={classes.circularProgress} />
        </Box>
      ) : (
        <div>
          <Card className={classes.section}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.cardTitle}>
                Mandate fulfillment status
              </Typography>
              {/*<AllocStatusTable*/}
              {/*  isJoint={isJoint}*/}
              {/*  columns={allocationTableData.columns}*/}
              {/*  rows={allocationStatusData}*/}
              {/*/>*/}
              <Table className={classes.table}>
                <TableHead className="MuiTableCell-head">
                  <TableRow>
                    <TableCell>Fulfillment status</TableCell>
                    <TableCell>Requirement</TableCell>
                    <TableCell>Required Volume</TableCell>
                    <TableCell>Joint fulfillment</TableCell>
                    <TableCell>Allocated(LE)</TableCell>
                    <TableCell>Allocated(15)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="MuiTableBody-root">
                  <TableRow>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>{mandateFulfillmentStatus.fulfillment_status.gasoline_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>Gasoline sub-mandate({mandateFulfillmentStatus.requirement.gasoline_mandate}%)</TableCell>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>{mandateFulfillmentStatus.req_vol.gasoline_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>{mandateFulfillmentStatus.joint_fulfillment.gasoline_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>{mandateFulfillmentStatus.allocated_LE.gasoline_mandate_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGasoline}}>{mandateFulfillmentStatus.allocated_15.gasoline_mandate_15}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>{mandateFulfillmentStatus.fulfillment_status.advanced_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>Advanced sub-mandate({mandateFulfillmentStatus.requirement.advanced_mandate}%)</TableCell>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>{mandateFulfillmentStatus.req_vol.advanced_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>{mandateFulfillmentStatus.joint_fulfillment.advanced_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>{mandateFulfillmentStatus.allocated_LE.advanced_mandate_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAdvanced}}>{mandateFulfillmentStatus.allocated_15.advanced_mandate_15}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>{mandateFulfillmentStatus.fulfillment_status.general_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>General mandate({mandateFulfillmentStatus.requirement.general_mandate}%)</TableCell>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>{mandateFulfillmentStatus.req_vol.general_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>{mandateFulfillmentStatus.joint_fulfillment.general_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>{mandateFulfillmentStatus.allocated_LE.general_mandate_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorGeneral}}>{mandateFulfillmentStatus.allocated_15.general_mandate_15}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>{mandateFulfillmentStatus.fulfillment_status.req_by_cust_mandate_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>Above the mandate(required by customers)</TableCell>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>{mandateFulfillmentStatus.req_vol.req_by_cust_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>{mandateFulfillmentStatus.joint_fulfillment.req_by_cust_mandate}</TableCell>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>{mandateFulfillmentStatus.allocated_LE.req_by_cust_mandate_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorReqByCust}}>{mandateFulfillmentStatus.allocated_15.req_by_cust_mandate_15}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>{mandateFulfillmentStatus.fulfillment_status.after_mandate_fulfill_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>Above the mandate(after mandate fulfillment)</TableCell>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>{mandateFulfillmentStatus.req_vol.after_mandate_fulfill}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>{mandateFulfillmentStatus.joint_fulfillment.after_mandate_fulfill}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>{mandateFulfillmentStatus.allocated_LE.after_mandate_fulfill_LE}</TableCell>
                    <TableCell style={{backgroundColor: statusColorAfterFulfill}}>{mandateFulfillmentStatus.allocated_15.after_mandate_fulfill_15}</TableCell>
                  </TableRow>
                </TableBody>

              </Table>
            </CardContent>
          </Card>

          <Card className={classes.section}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.cardTitle}>
                Volume summary
              </Typography>
              <Table className={classes.table}>
                <TableHead className="MuiTableCell-head">
                  <TableRow>
                    <TableCell>GASOLINE products</TableCell>
                    <TableCell>Sold volume</TableCell>
                    <TableCell>Allocated volume</TableCell>
                    <TableCell>Diesel products</TableCell>
                    <TableCell>Sold volumes</TableCell>
                    <TableCell>Allocated volumes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="MuiTableBody-root">
                  <TableRow>
                   <TableCell>Gasoline</TableCell>
                    <TableCell>{volumeSummary.sold.gasoline}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Diesel</TableCell>
                    <TableCell>{volumeSummary.sold.diesel}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>Bio components</TableCell>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>{volumeSummary.sold.biocomponent_in_gasoline}</TableCell>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>{volumeSummary.allocated.biocomponent_in_gasoline}</TableCell>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>Bio components</TableCell>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>{volumeSummary.sold.biocomponent_in_diesel}</TableCell>
                    <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>{volumeSummary.allocated.biocomponent_in_diesel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Etanol</TableCell>
                    <TableCell>{volumeSummary.sold.etanol}</TableCell>
                    <TableCell>{volumeSummary.allocated.etanol}</TableCell>
                    <TableCell>Fame</TableCell>
                    <TableCell>{volumeSummary.sold.fame}</TableCell>
                    <TableCell>{volumeSummary.allocated.fame}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Etanol (advanced)</TableCell>
                    <TableCell>{volumeSummary.sold.etanol_advanced}</TableCell>
                    <TableCell>{volumeSummary.allocated.etanol_advanced}</TableCell>
                    <TableCell>Fame (advanced)</TableCell>
                    <TableCell>{volumeSummary.sold.fame_advanced}</TableCell>
                    <TableCell>{volumeSummary.allocated.fame_advanced}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nafta</TableCell>
                    <TableCell>{volumeSummary.sold.nafta}</TableCell>
                    <TableCell>{volumeSummary.allocated.nafta}</TableCell>
                    <TableCell>HVO/HRO</TableCell>
                    <TableCell>{volumeSummary.sold.hvo_hro}</TableCell>
                    <TableCell>{volumeSummary.allocated.hvo_hro}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nafta (advanced)</TableCell>
                    <TableCell>{volumeSummary.sold.nafta_advanced}</TableCell>
                    <TableCell>{volumeSummary.allocated.nafta_advanced}</TableCell>
                    <TableCell>HVO/HRO (advanced)</TableCell>
                    <TableCell>{volumeSummary.sold.hvo_hro_advanced}</TableCell>
                    <TableCell>{volumeSummary.allocated.hvo_hro_advanced}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Biogasoline</TableCell>
                    <TableCell>{volumeSummary.sold.biogasoline}</TableCell>
                    <TableCell>{volumeSummary.allocated.biogasoline}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Biogasoline (advanced)</TableCell>
                    <TableCell>{volumeSummary.sold.biogasoline_advanced}</TableCell>
                    <TableCell>{volumeSummary.allocated.biogasoline_advanced}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Total gasoline volume</TableCell>
                    <TableCell>{volumeSummary.sold.total_gasoline}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total diesel volume</TableCell>
                    <TableCell>{volumeSummary.sold.total_diesel}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </CardContent>
          </Card>

          <Card className={classes.section}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.cardTitle}>
                Allocated batches
              </Typography>
              {/*<SimpleTable*/}
              {/*  columns={reportedTableData.columns}*/}
              {/*  rows={reportedVolumesData}*/}
              {/*/>*/}

              <Table className={classes.table}>
                <TableHead className="MuiTableCell-head">
                  <TableRow>
                    <TableCell>Batch#</TableCell>
                    <TableCell>Biofuel Type</TableCell>
                    <TableCell>Volume(L)</TableCell>
                    <TableCell>Raw material</TableCell>
                    <TableCell>Advanced</TableCell>
                    <TableCell>Origin</TableCell>
                    <TableCell>Ghg red.</TableCell>
                    <TableCell>Storage terminal</TableCell>
                    <TableCell>Certification scheme</TableCell>
                    <TableCell>Certification ID</TableCell>
                    <TableCell>Etot</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="MuiTableBody-root">
                  {
                    allocatedBatches.map((biotemplate: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{biotemplate.biofuel_type}</TableCell>
                          <TableCell>{biotemplate.volume}</TableCell>
                          <TableCell>{biotemplate.raw_material}</TableCell>
                          <TableCell>{biotemplate.double_counted_norway}</TableCell>
                          <TableCell>{biotemplate.raw_material_origin}</TableCell>
                          <TableCell>{biotemplate.reduction_in_climate_emission}</TableCell>
                          <TableCell>{biotemplate.storage_terminal}</TableCell>
                          <TableCell>{biotemplate.documentation_emission_of_climate}</TableCell>
                          <TableCell>{biotemplate.certification_id}</TableCell>
                          <TableCell>{biotemplate.etot}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                    ))
                  }
                </TableBody>


              </Table>
            </CardContent>
          </Card>
          {/* new table availablePoolBiotemplates */}
          {/* {checkNull(availPoolData) && ( */}

          {/*<Card className={classes.section}>*/}
          {/*  <CardContent className={classes.cardContent}>*/}
          {/*    <Typography variant="h5" className={classes.cardTitle}>*/}
          {/*      <FormattedMessage id="sustainabilityView.reportsView.availablePoolBiotemplates" />*/}
          {/*    </Typography>*/}
          {/*    <SimpleTable*/}
          {/*      columns={availablePoolBiotemplatesData.columns}*/}
          {/*      rows={availPoolData}*/}
          {/*    />*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}

          {/* )} */}

          {/* new pool status table */}
          {/*<Card className={classes.section}>*/}
          {/*  <CardContent className={classes.cardContent}>*/}
          {/*    <Typography variant="h5" className={classes.cardTitle}>*/}
          {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatus" />*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="body1" className={classes.cardSubTitle}>*/}
          {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatusSubtitle1" />*/}
          {/*    </Typography>*/}

          {/*    <SimpleTable*/}
          {/*      columns={poolStatusDieselData.columns}*/}
          {/*      rows={dieselData}*/}
          {/*    />*/}
          {/*    <Typography variant="body1" className={classes.cardSubTitle}>*/}
          {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatusSubtitle2" />*/}
          {/*    </Typography>*/}
          {/*    <SimpleTable*/}
          {/*      columns={poolStatusGasolineData.columns}*/}
          {/*      rows={gasolineData}*/}
          {/*    />*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}
          {isJoint && (
            <Card className={classes.section}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" className={classes.cardTitle}>
                  <FormattedMessage id="sustainabilityView.reportsView.jointFulfillment" />
                </Typography>
                <SimpleTable
                  columns={jointTableData.columns}
                  rows={jointFulfillmentData}
                />
              </CardContent>
            </Card>
          )}
          {data &&
            data.annual_calculations &&
            data.annual_calculations[selectedPartIndex] && (
              <Card className={classes.section}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.cardTitle}>
                    <FormattedMessage id="sustainabilityView.reportsView.annualReport" />
                  </Typography>

                  <>
                    <Typography
                      variant="body1"
                      className={classes.cardSubTitle}
                    >
                      <FormattedMessage id="sustainabilityView.reportsView.aggregated.tableTitle" />
                    </Typography>

                    <SmartTable
                      whiteHeader
                      verticalOverflow
                      headCells={aggregatedTableData.smartColumns}
                      rows={
                        Array.isArray(
                          data.annual_calculations[selectedPartIndex]
                            .reported_quantities
                        )
                          ? data.annual_calculations[selectedPartIndex]
                              .reported_quantities
                          : null
                      }
                    />
                  </>

                  <>
                    <Typography
                      variant="body1"
                      className={classes.cardSubTitle}
                    >
                      <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.tableTitle" />
                    </Typography>

                    <SmartTable
                      whiteHeader
                      verticalOverflow
                      headCells={summarizedReportTableData.smartColumns}
                      rows={
                        Array.isArray(
                          data.annual_calculations[selectedPartIndex].reports
                        )
                          ? data.annual_calculations[selectedPartIndex].reports
                          : null
                      }
                    />
                  </>

                  <>
                    <Typography
                      variant="body1"
                      className={classes.cardSubTitle}
                    >
                      <FormattedMessage id="sustainabilityView.reportsView.calculationTax.tableTitle" />
                    </Typography>

                    <SmartTable
                      whiteHeader
                      verticalOverflow
                      headCells={calculationTaxTableData.smartColumns}
                      rows={
                        Array.isArray(
                          data.annual_calculations[selectedPartIndex]
                            .tax_credits
                        )
                          ? data.annual_calculations[selectedPartIndex]
                              .tax_credits
                          : null
                      }
                    />
                  </>
                </CardContent>
              </Card>
            )}
        </div>
      )}
      <ModalInfo
        text={errorMessage}
        open={showErrorModal}
        onClose={closeErrorModal}
      />
      <ModalConfirmDelivery
        open={showConfirmDeliveryModal}
        onClose={closeConfirmDeliveryModal}
        onConfirm={() => {
          closeConfirmDeliveryModal();
          handleConfirmationStatus();
        }}
      />
    </div>
  );
};

export default ReportsView;
