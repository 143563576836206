import React from "react";
import clsx from "clsx";
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MarineCustomerReqTable from "./MarineCustomerReqTable";
import Box from "@material-ui/core/Box";

const ExpansionPanel = withStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    "&.Mui-expanded:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
}))(MuiExpansionPanel);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        // width: "100%",
      },
      row: { width: "100%" },
      heading: {
        fontWeight: 500,
      },
      circle: {
        margin: theme.spacing(1),
        width: "12px",
        height: "12px",
        borderRadius: "100%",
      },
      green: {
        backgroundColor: "#8fcc60",
      },
      red: {
        backgroundColor: "#ff441a",
      },
      yellow: {
        backgroundColor: "#ffac12",
      },
      btnAllocation: (props: any) => ({
        marginTop: theme.spacing(2),
        marginBottom: props.showAllocation ? theme.spacing(2) : 0,
      }),
      iconExpand: {
        borderRadius: "100%",
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.main,
        transform: "scale(0.9)",
        ".MuiExpansionPanelSummary-expandIcon.Mui-expanded &": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        },
      },
    })
);

interface Props {
  stepData: any;
  data: any;
  available_quantities: any;
  customer: { id: number; index: number };
  allocateUpdateCustomerReq: any;
}

const MarineCustomerReqPanel: React.FC<Props> = ({
                                             stepData,
                                             data,
                                             available_quantities,
                                             customer,
                                             allocateUpdateCustomerReq,
                                           }) => {
  const classes = useStyles();
  /*
  const getAllocated = (requirement: any): number => {
    let value = 0;
    if (
      allocated_sorted[customer.id] &&
      allocated_sorted[customer.id][requirement.id]
    )
      requirement.biotemplates.map(
        (row: any, index: number) =>
          (value += allocated_sorted[customer.id][requirement.id][row.id]
            ? +allocated_sorted[customer.id][requirement.id][row.id]
            : 0)
      );
    else value = 0;
    return value;
  }; */

  const isComplete = (cust_id: any) => {
    let isComplete = true;
    if (data && data.customer_requirements)
      data.customer_requirements.map((req: any, req_idx: number) => {
        if (
            stepData.sumAllocated[cust_id][req.id]["fame"] <
            req.required_emission_reduction_fame ||
            stepData.sumAllocated[cust_id][req.id]["hvo_hro"] <
            req.required_emission_reduction_hvo_hro ||
            stepData.sumAllocated[cust_id][req.id]["etanol"] <
            req.required_emission_reduction_etanol ||
            stepData.sumAllocated[cust_id][req.id]["nafta"] <
            req.required_emission_reduction_nafta
        )
          isComplete = false;
      });
    return isComplete;
  };

  const getStatus = (cust_id: any): string => {
    let status = "Complete";
    if (data && data.customer_requirements)
      for (var i = 0; i < data.customer_requirements.length; i++) {
        let req = data.customer_requirements[i];
        let sumFame = stepData.sumAllocated[cust_id][req.id]["fame"];
        let sumHvoHro = stepData.sumAllocated[cust_id][req.id]["hvo_hro"];
        let sumEtanol = stepData.sumAllocated[cust_id][req.id]["etanol"];
        let sumNafta = stepData.sumAllocated[cust_id][req.id]["nafta"];

        if (
            sumFame < req.required_emission_reduction_fame ||
            sumHvoHro < req.required_emission_reduction_hvo_hro ||
            sumEtanol < req.required_emission_reduction_etanol ||
            sumNafta < req.required_emission_reduction_nafta
        )
          return (status = "Not Complete");
        if (
            sumFame > req.required_emission_reduction_fame ||
            sumHvoHro > req.required_emission_reduction_hvo_hro ||
            sumEtanol > req.required_emission_reduction_etanol ||
            sumNafta > req.required_emission_reduction_nafta
        )
          status = "Overallocated";
      }
    return status;
  };

  return (
      <div>
        <ExpansionPanel>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.iconExpand} />}
          >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.row}
            >
              <Typography
                  className={classes.heading}
                  variant="body1"
                  color="primary"
              >
                {data.name}
              </Typography>
              <Box display="flex" alignItems="center">
                <div
                    className={clsx(classes.circle, {
                      [classes.red]: getStatus(customer.id) === "Not Complete",
                      [classes.yellow]: getStatus(customer.id) === "Overallocated",
                      [classes.green]: getStatus(customer.id) === "Complete",
                    })}
                ></div>
                <Typography variant="body2" color="primary">
                  {data.requirements_count > 1
                      ? data.requirements_count + " requirements"
                      : data.requirements_count + " requirement"}
                </Typography>
              </Box>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MarineCustomerReqTable
                stepData={stepData}
                requirements={data.customer_requirements}
                available_quantities={available_quantities}
                customer={customer}
                allocateUpdateCustomerReq={allocateUpdateCustomerReq}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
  );
};

export default MarineCustomerReqPanel;
