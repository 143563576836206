/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const getStepData = async (activeStep: number) => {
  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;

    default:
      stepreturn = "step_two";
      break;
  }

  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/offroad_report_generate/${stepreturn}?mandate=marine`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const putStepOverViewData = async (activeStep: number, params: any) => {
  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;
    case 5:
      stepreturn = "step_six";
      break;

    default:
      stepreturn = "step_two";
      break;
  }
  if (token && isAdmin()) {
    try {
      console.log("put put put ", params);
      const response = await fetch(`${BASE_URL}/api/offroad_report_generate/${stepreturn}?mandate=marine`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const postStepBiotemplatesData = async (activeStep: number, params: any) => {

  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;
    case 5:
      stepreturn = "step_six";
      break;

    default:
      stepreturn = "step_two";
      break;
  }
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/offroad_report_generate/${stepreturn}?mandate=marine`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const postSetActiveStepMarine = async (activeStep: number) => {
  const token = getToken();
  const params = {stage: activeStep};
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/set_active_step?mandate=marine`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const sustainabilityPoolMarine = async () => {
  const token = getToken();
  let valid = false;
  let result;

  if (token) {
    try {
      const response = await fetch(
          `${BASE_URL}/api/sustainability_reports/marine_allocation_status`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
      );

      result = await response.json();
      valid =
          result &&
          result.success &&
          result.sustainable_batches &&
          result.allocation_status_volume &&
          result.allocation_status_ton_equivalence;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (result && result.errors) {
      return {
        valid: false,
        message: result.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    sustainable_batches: result.sustainable_batches,
    allocation_status_volume: result.allocation_status_volume,
    allocation_status_ton_equivalence: result.allocation_status_ton_equivalence,
  };
};
export const resetAllocationMarine = async () => {
  const token = getToken();

  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/offroad_report_generate/reset_allocation?mandate=marine`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

// export default resetAllocationMarine();
