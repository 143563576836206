import React from "react";
import { FormattedMessage } from "react-intl";

const sections = {
  product_split: {
    name: (
      <FormattedMessage id="settingsView.index.soldComponents.productSplit" />
    ),
    size: 5,
  },
  ghg_reduction: {
    name: (
      <FormattedMessage id="settingsView.index.soldComponents.ghgReduction" />
    ),
    size: 4,
  },
};

export default [
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.product" />,
    id: "name",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.soldComponents.productNumber" />
    ),
    id: "product_number",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.usageArea" />,
    id: "usage_area",
    type: "select",
    params: "usage_area",
    // filter_value: ["Marine"],
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fuelType" />,
    id: "fuel_type",
    type: "select",
    params: "sold_components_fuel_types",
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.valueType" />,
    id: "default_ghg_value",
    type: "text",
    boolean_value: ["Default", "Custom"],
    hide: true,
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fame" />,
    id: "rme",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    section_start: true,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fame_advanced" />,
    id: "rme_advanced",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.hvo_hro" />,
    id: "hvo",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.hvo_hro_advanced" />,
    id: "hvo_advanced",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.etanol" />,
    id: "etanol",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    section_middle: true,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.etanol_advanced" />,
    id: "etanol_advanced",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.nafta" />,
    id: "nafta",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.nafta_advanced" />,
    id: "nafta_advanced",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.biogasoline" />,
    id: "biogasoline",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.biogasoline_advanced" />,
    id: "biogasoline_advanced",
    type: "text",
    decimals_format: 2,
    section: sections.product_split,
    initValue: 0,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fossil" />,
    id: "fossil",
    type: "text",
    optionnal: true,
    disabled: true,
    autofill: [100, "-nafta", "-hvo", "-etanol", "-rme","-biogasoline",
      "-nafta_advanced", "-hvo_advanced", "-etanol_advanced", "-rme_advanced","-biogasoline_advanced"],
    decimals_format: 2,
    section: sections.product_split,
    visibility_condition: { id: "fuel_type" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fame" />,
    id: "fame_percentage",
    type: "text",
    // initValue: ["ghg_rates", "fame"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    section_start: true,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.fame_advanced" />,
    id: "fame_advanced_percentage",
    type: "text",
    // initValue: ["ghg_rates", "fame"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.hvo_hro" />,
    id: "hvo_hro_percentage",
    type: "text",
    // initValue: ["ghg_rates", "hvo/hro"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.hvo_hro_advanced" />,
    id: "hvo_hro_advanced_percentage",
    type: "text",
    // initValue: ["ghg_rates", "hvo/hro"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Diesel" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.etanol" />,
    id: "etanol_percentage",
    type: "text",
    // initValue: ["ghg_rates", "etanol"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    section_middle: true,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.etanol_advanced" />,
    id: "etanol_advanced_percentage",
    type: "text",
    // initValue: ["ghg_rates", "etanol"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.nafta" />,
    id: "nafta_percentage",
    type: "text",
    // initValue: ["ghg_rates", "nafta"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.nafta_advanced" />,
    id: "nafta_advanced_percentage",
    type: "text",
    // initValue: ["ghg_rates", "nafta"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.biogasoline" />,
    id: "biogasoline_percentage",
    type: "text",
    // initValue: ["ghg_rates", "nafta"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
  {
    name: <FormattedMessage id="settingsView.index.soldComponents.biogasoline_advanced" />,
    id: "biogasoline_advanced_percentage",
    type: "text",
    // initValue: ["ghg_rates", "nafta"],
    initValue: 0,
    decimals_format: 2,
    section: sections.ghg_reduction,
    visibility_condition: { id: "fuel_type", value: "Gasoline" },
  },
];
