import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CreatableSelect from "react-select/creatable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormattedMessage } from "react-intl";
import postBkvUnMapData from "../../../../api/settings/bkvMappings/postBkvUnMapData";
import moment from "moment";
import getAppVariables from "../../../../api/settings/appVariables/get";
import ModalHblPreview from "./ModalHblPreview";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {postBiotemplateReconciliation} from "../../../../api/sustainability/storage/reconciliation/postBiotemplateReconciliation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    noteText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    commentTitle: {
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface ModalSaveReconciliationProps {
  open: boolean;
  onClose: () => void;
  data: any;
  generalData: {
    volume: any;
    reconcile_volume: any;
    biofuel_type: any;
    storage_terminal: any;
    status: any;
  };
}

const ModalSaveReconciliation: React.FC<ModalSaveReconciliationProps> = ({
  open,
  onClose,
  data,
  generalData,
}) => {
  const [formData, setFormData] = useState<any[]>([]);
  const [comment, setComment] = useState<string>("");
  const [reconciliationDate, setReconciliationDate] = useState<string>("");
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const hasData: boolean = Array.isArray(data) && data.length > 0;

  const isError: boolean = !reconciliationDate || !comment || comment.length < 1;

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const handleCloseSuccessModal = () => setSuccessModalOpen(false);
  const handleRedirect = () => {
    setSuccessModalOpen(false);
    history.goBack();
  };

  const handleDataSubmit = async () => {
    const res = await postBiotemplateReconciliation({
      volume: generalData.reconcile_volume,
      storage_terminal: generalData.storage_terminal,
      biofuel_type: generalData.biofuel_type,
      status: generalData.status,
      comment,
      reconcile_date: reconciliationDate,
      biotemplates: data
    });

    if (res) {
      setSuccessModalOpen(true);
    } else {
      alert("Some error occured");
    }
  };

  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setReconciliationDate(formattedDate);
    } else {
      setReconciliationDate("");
    }
  };

  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  useEffect(() => {
    formData.push(
      {
        reconciliation_date: new Date(),
        comment: "",
      }
    );
  }, [generalData]);

  // @ts-ignore
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Save Transaction"
      maxWidth="xs"
    >
      <div className={classes.modalContent}>
        <Typography variant="body1" paragraph className={classes.noteText}>
          Note: Saving this transaction will reset your allocation (reporting
          and simulation)
        </Typography>
        <Typography variant="body1" paragraph>
          To save the transaction, leave a comment describing what changes were
          made and why.
        </Typography>
        <Typography variant="body1" className={classes.commentTitle}>
          Reconcilication Date <RequiredAsterisk />
        </Typography>
        <KeyboardDatePicker
            className={classes.marginBottom}
            value={reconciliationDate ? reconciliationDate : null}
            placeholder={moment(new Date()).format("YYYY-MM-DD")}
            name="reconciliation_date"
            onChange={handleDateChange}
            inputVariant="outlined"
            format="YYYY-MM-DD"
            invalidDateMessage="Invalid Date"
        />

        <Typography variant="body1" className={classes.commentTitle}>
          Comment <RequiredAsterisk />
        </Typography>

        <TextField
            className={classes.marginBottom}
            variant="outlined"
            name="comment"
            placeholder="Write a comment"
            multiline
            fullWidth
            rows={4}
            value={comment}
            onChange={e => setComment(e.target.value)}
        />

        <Grid container className={classes.btnContainer} spacing={2}>
          <Grid item xs>
            <ButtonRounded
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onClose}
            >
              Cancel
            </ButtonRounded>
          </Grid>
          <Grid item xs>
            <ButtonRounded
                fullWidth
                variant="contained"
                color="primary"
                disabled={isError}
                onClick={handleDataSubmit}
            >
              Save
            </ButtonRounded>
          </Grid>
        </Grid>

        {successModalOpen && (
            <ModalContainer
                open={successModalOpen}
                onClose={handleCloseSuccessModal}
                title="Saved Successfully"
                maxWidth="xs"
            >
              <div
                  style={{
                    textAlign: "center",
                    padding: "16px",
                  }}
              >
                <Typography variant="body1" gutterBottom>
                  Your changes have been saved successfully!
                </Typography>
                <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    alignItems={"center"}
                    justify={"center"}
                >
                  <Grid item>
                    <br></br>
                    <ButtonRounded
                        variant="outlined"
                        color="secondary"
                        onClick={handleRedirect}
                    >
                      Redirect to Previous Page
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </div>
            </ModalContainer>
        )}

        {isError && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}>
            Please Filled all required field.
          </Typography>
        )}

        {!hasData && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            Allocated quantity in the table can not be blank.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalSaveReconciliation;
